import { norwegian } from "~/assets/translations/primevue";
import FloatLabel from 'primevue/floatlabel';
import { defineNuxtPlugin } from "#app";
import PrimeVue from "primevue/config";
import Tooltip from 'primevue/tooltip';
import ProgressSpinner from 'primevue/progressspinner';
import Calendar from 'primevue/calendar';
import Button from 'primevue/button';
import InputText from 'primevue/inputtext';
import RadioButton from 'primevue/radiobutton';
import ConfirmationService from 'primevue/confirmationservice';
import Menu from 'primevue/menu';
import FileUpload from 'primevue/fileupload';
import Message from 'primevue/message';
import InputNumber from 'primevue/inputnumber';
import Editor from 'primevue/editor';
import Galleria from 'primevue/galleria';
import Dropdown from 'primevue/dropdown';
import Checkbox from 'primevue/checkbox';
import ConfirmDialog from 'primevue/confirmdialog';
import Dialog from 'primevue/dialog';
import { useConfirm } from "primevue/useconfirm";
import Divider from "primevue/divider";
import Password from 'primevue/password';
import InputSwitch from 'primevue/inputswitch'
import SelectButton from 'primevue/selectbutton'
import Textarea from 'primevue/textarea'
import Steps from 'primevue/steps'
import ToastService from 'primevue/toastservice'
import Toast from 'primevue/toast'
import Stepper from 'primevue/stepper'
import StepperPanel from 'primevue/stepperpanel'
import AutoComplete from 'primevue/autocomplete'
import DataTable from 'primevue/datatable';
import Column from 'primevue/column';
import Badge from 'primevue/badge';
import MultiSelect from 'primevue/multiselect';
import { FilterService } from 'primevue/api';
import TabView from 'primevue/tabview';
import TabPanel from 'primevue/tabpanel';

export default defineNuxtPlugin((nuxtApp) => {
    nuxtApp.vueApp.use(PrimeVue, { 
      ripple: true,
      locale: norwegian,
    })
    nuxtApp.vueApp.use(ConfirmationService)
    nuxtApp.vueApp.use(ToastService);
    nuxtApp.vueApp.use(FilterService);
    nuxtApp.vueApp.directive('Tooltip', Tooltip)
    nuxtApp.vueApp.directive('FileUpload', FileUpload)
    nuxtApp.vueApp.component('DataTable', DataTable)
    nuxtApp.vueApp.component('Column', Column)
    
    nuxtApp.vueApp.component('TabView', TabView)
    nuxtApp.vueApp.component('TabPanel', TabPanel)
    
    nuxtApp.vueApp.component('Badge', Badge)
    nuxtApp.vueApp.component('MultiSelect', MultiSelect)
    nuxtApp.vueApp.component('Toast', Toast)
    nuxtApp.vueApp.component('Steps', Steps)
    nuxtApp.vueApp.component('AutoComplete', AutoComplete)
    nuxtApp.vueApp.component('Stepper', Stepper)
    nuxtApp.vueApp.component('StepperPanel', StepperPanel)
    nuxtApp.vueApp.component('SelectButton', SelectButton)
    nuxtApp.vueApp.component('FloatLabel', FloatLabel)
    nuxtApp.vueApp.component("Textarea", Textarea)
    nuxtApp.vueApp.component("InputSwitch", InputSwitch)
    nuxtApp.vueApp.component("Dropdown", Dropdown)
    nuxtApp.vueApp.component("Button", Button)
    nuxtApp.vueApp.component("Menu", Menu)
    nuxtApp.vueApp.component("Divider", Divider)
    nuxtApp.vueApp.component("Editor", Editor)
    nuxtApp.vueApp.component("Checkbox", Checkbox)
    nuxtApp.vueApp.component("Dialog", Dialog)
    nuxtApp.vueApp.component("ConfirmDialog", ConfirmDialog)
    nuxtApp.vueApp.component("useConfirm", useConfirm)
    nuxtApp.vueApp.component("InputNumber", InputNumber)
    nuxtApp.vueApp.component("Galleria", Galleria)
    nuxtApp.vueApp.component("FileUpload", FileUpload)
    nuxtApp.vueApp.component("Message", Message)
    nuxtApp.vueApp.component("InputText", InputText)
    nuxtApp.vueApp.component("RadioButton", RadioButton)
    nuxtApp.vueApp.component("ProgressSpinner", ProgressSpinner)
    nuxtApp.vueApp.component("Password", Password)
    nuxtApp.vueApp.component("Calendar", Calendar)
    
    return {
      provide: {
        useConfirm: nuxtApp.vueApp.config.globalProperties.$confirm
      }
    }
    
});